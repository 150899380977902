import React from 'react';
import { useState } from 'react';
import { Container, Navbar, Nav, NavDropdown, Image, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';



function AppNavbar() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {/* <header className='bg-dark'>
        <Container fluid>
          <Row>
            <Col lg={4}>
              <Link><i class="fa-solid fa-phone"></i>+91 141 492 0777 </Link>
              <Link><i class="fa fa-retweet"></i>Call Back</Link>
            </Col>
            <Col lg={8} className='d-flex justify-content-end align-items-center'>
              <Link><i class="fa-regular fa-envelope"></i>demo122@gmail.com</Link>
            </Col>
          </Row>
        </Container>
      </header> */}
      <div className='mynavbar position-sticky top-0 border-bottom'>
        <Container fluid>
          <Navbar expand="lg">
            <Container fluid>
              <Link to="/">
                <Navbar.Brand>
                  <Image src="assets/images/logo.png" width={250} alt="Logo" />
                </Navbar.Brand>
              </Link>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="mx-auto my-2 my-lg-0"
                  style={{ maxHeight: '100px' }}
                  navbarScroll
                >
                  <Nav.Link as={Link} to="/">Home</Nav.Link>
                  <Nav.Link as={Link} to="/about">About Us</Nav.Link>
                  <NavDropdown title="Privacy & Policy" id="navbarScrollingDropdown">
                    <NavDropdown.Item href="#action3">FAQ'S</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Customer Greivences
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Terms And Conditions
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Privacy Policy
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Button className='main-btn' onClick={handleShow}>Get A Free Quate!</Button>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>
      </div>
      <Modal show={show} onHide={handleClose} className='my-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Get A Free Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Add your form fields and other content here */}
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" />
            </Form.Group>
            <Form.Group controlId="formEmail" className='my-3'>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter your email" />
            </Form.Group>
            <Form.Group controlId="formMobile" className='my-3'>
              <Form.Label>Mobile</Form.Label>
              <Form.Control type="tel" placeholder="Enter your mobile number" />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Enter your message" />
            </Form.Group>
            {/* Add more form fields as needed */}
          </Form>
        </Modal.Body>
        <Modal.Footer className='border-0'>
          <Button className='main-btn mx-auto' onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}
export default AppNavbar;
