// ClientPartnersCarousel.js

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const clientPartners = [
  // { id: 1, name: 'Company A', logo: '/assets/images/partner4.png' },
  { id: 2, name: 'Company B', logo: '/assets/images/partner.png' },
  { id: 3, name: 'Company C', logo: '/assets/images/partner1.png' },
  { id: 4, name: 'Company D', logo: '/assets/images/partner2.png' },
  { id: 2, name: 'Company B', logo: '/assets/images/partner.png' },
  { id: 4, name: 'Company E', logo: '/assets/images/partner3.png' },
];

const ClientPartnersCarousel = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Number of logos to show at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Adjust autoplay speed as needed
    arrows: false, // Hide next/prev arrows
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className='py-5 our-partners'>
      <div className='main-heading'>
          <h2>Our <span>Parnter</span></h2>
      </div>
      <Slider {...sliderSettings}>
        {clientPartners.map((partner) => (
          <div key={partner.id} className="partner-item">
            <img src={partner.logo} alt={partner.name}/>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default ClientPartnersCarousel;
