import React from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom'

function FollowPage() {
  return (
     <>
     <section className='follow-us'>
       <Container>
        <Row>
            <Col lg={12}>
                <h2>Streamline Your Hiring Process with Ease-Unlock the Power of Our AI-based Applicant Tracking System</h2>
                <a href="https://wa.link/7r83iz" target="_blank">WHATHSAPP NOW +91 9318331978</a>
            </Col> 
        </Row>
       </Container>
     </section>
     </>
  )
}

export default FollowPage
