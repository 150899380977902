
import { Container, Row, Col, Tab, Nav, Card, Button, Image } from "react-bootstrap";

function LeftTabsExample() {
    return (
        <section className="py-5 our-services">
            <div className="main-heading">
                 <h2>Our <span>Services</span></h2>
            </div>
            <Container>
                <Row>
                    <Col>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">SEO</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Web Designing</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Web Develoment</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="fifth">Graphich Designing</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="sixth">Logo Designing</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="seventh">App Develoment</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={9}>
                                    <Tab.Content className="my-services">
                                        <Tab.Pane eventKey="first">
                                            <Card className="shadow">
                                                <Card.Body className="clearfix">
                                                    <Image src="assets/images/1.jpg" alt="logo.png" width={150} />
                                                    <h4>Marketing Mastery</h4>
                                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorum explicabo, sunt, iste ipsam culpa accusamus rem ab harum praesentium delectus qui placeat soluta minima inventore ipsa quaerat quisquam. Dignissimos, facere.</p>
                                                    <Button className="learn-more-btn">Know More</Button>
                                                    <ul className="mt-3">
                                                        <li>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                        </li>
                                                        <li>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                        </li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <Card className="shadow">
                                                <Card.Body className="clearfix">
                                                    <Image src="assets/images/1.jpg" alt="logo.png" width={150} />
                                                    <h4>Marketing Mastery</h4>
                                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorum explicabo, sunt, iste ipsam culpa accusamus rem ab harum praesentium delectus qui placeat soluta minima inventore ipsa quaerat quisquam. Dignissimos, facere.</p>
                                                    <Button className="learn-more-btn">Know More</Button>
                                                    <ul className="mt-3">
                                                        <li>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                        </li>
                                                        <li>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                        </li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <Card className="shadow">
                                                <Card.Body className="clearfix">
                                                    <Image src="assets/images/1.jpg" alt="logo.png" width={150} />
                                                    <h4>Marketing Mastery</h4>
                                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorum explicabo, sunt, iste ipsam culpa accusamus rem ab harum praesentium delectus qui placeat soluta minima inventore ipsa quaerat quisquam. Dignissimos, facere.</p>
                                                    <Button className="learn-more-btn">Know More</Button>
                                                    <ul className="mt-3">
                                                        <li>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                        </li>
                                                        <li>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                        </li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fifth">
                                            <Card className="shadow">
                                                <Card.Body className="clearfix">
                                                    <Image src="assets/images/1.jpg" alt="logo.png" width={150} />
                                                    <h4>Marketing Mastery</h4>
                                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorum explicabo, sunt, iste ipsam culpa accusamus rem ab harum praesentium delectus qui placeat soluta minima inventore ipsa quaerat quisquam. Dignissimos, facere.</p>
                                                    <Button className="learn-more-btn">Know More</Button>
                                                    <ul className="mt-3">
                                                        <li>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                        </li>
                                                        <li>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                        </li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="sixth">
                                            <Card className="shadow">
                                                <Card.Body className="clearfix">
                                                    <Image src="assets/images/1.jpg" alt="logo.png" width={150} />
                                                    <h4>Marketing Mastery</h4>
                                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorum explicabo, sunt, iste ipsam culpa accusamus rem ab harum praesentium delectus qui placeat soluta minima inventore ipsa quaerat quisquam. Dignissimos, facere.</p>
                                                    <Button className="learn-more-btn">Know More</Button>
                                                    <ul className="mt-3">
                                                        <li>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                        </li>
                                                        <li>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                        </li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="seventh">
                                            <Card className="shadow">
                                                <Card.Body className="clearfix">
                                                    <Image src="assets/images/1.jpg" alt="logo.png" width={150} />
                                                    <h4>Marketing Mastery</h4>
                                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorum explicabo, sunt, iste ipsam culpa accusamus rem ab harum praesentium delectus qui placeat soluta minima inventore ipsa quaerat quisquam. Dignissimos, facere.</p>
                                                    <Button className="learn-more-btn">Know More</Button>
                                                    <ul className="mt-3">
                                                        <li>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                        </li>
                                                        <li>
                                                            <p><i class="fa-solid fa-check"></i>Live Q&A Support</p>
                                                        </li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default LeftTabsExample;