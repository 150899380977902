import React, { useState } from "react";
import { Container, Row, Col, Button, } from "react-bootstrap";


function HomeHeader() {
  return (
    <section className="home-header">
      <Container fluid>
        <Row>
          <Col xxl={12} xl={6} lg={6} md={6} sm={6}>
              <h3>Create Your <span> Website</span></h3>
              <h1>Digital marketing<br></br>agency</h1>
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officia aliquam nesciunt rem. Eligendi, ratione nulla vitae fuga alias assumenda id eum, quisquam corrupti dignissimos perspiciatis soluta eaque cumque reprehenderit eos!</p>
              <Button>Learn More</Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default HomeHeader;