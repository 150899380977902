import React from 'react';
import { Col, Container, Row, } from "react-bootstrap";

function User() {
    const data = [
        {
            id: 1,
            src: "assets/images/ux.png",
            title: "Marketing Analysis",
            subtitle: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ex corporis iure nihil quos,"
        },
        {
            id: 3,
            src: "assets/images/ux.png",
            title: "Marketing Analysis",
            subtitle: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ex corporis iure nihil quos,"
        },
        {
            id: 3,
            src: "assets/images/ux.png",
            title: "Marketing Analysis",
            subtitle: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ex corporis iure nihil quos,"
        }
    ];
    return (
        <>
            <section className='our-features text-center my-5'>
                <div className='main-heading'>
                     <h2>Our <span>Features</span></h2>
                </div>
                <Container>
                    <Row >
                        {data.map((ram) => (
                            <Col key={ram.id} lg={4} className='border-right'>
                                <div className='feature-text'>
                                    <div className='img-bg'>
                                       <img src={ram.src} />
                                    </div>
                                    <h4>{ram.title}</h4>
                                    <p>{ram.subtitle}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        </>
    );
}       
export default User;

