import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

function GetInfo() {
  return (
    <>
      <section className='py-5 getinfo'>
        <Container>
          <Row>
            <Col lg={6}>
              <img src="assets/images/man.png" alt="man.png" width={600} className='img-fluid' />
            </Col>
            <Col lg={6} className='p-5'>
              <h4>You Are Going To <br></br>Think That You Can Not<br></br> Afford Us</h4>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate sed rem nihil possimus fuga facere molestias, necessitatibus eum inventore aperiam! Quos molestias consequatur sunt. Nesciunt amet distinctio magnam officiis facere.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default GetInfo
