import React from 'react';
import AppNavbar from './component/AppNavbar';
import { Routes, Route } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import './css/App.css';
import Home from './Pages/home';
import About from './component/About';
import User from './component/OurFeatures';
import OurClient from './component/OurClient';
import PageNotFound from './PageNotFound';
import Footer from './component/Footer';
import GetInfo from './component/GetInfo';
import HowWorks from './component/HowWorks';
import FollowPage from './component/FollowPage';
import OurServices from './component/OurServices';
import ConnectUs from './component/ConnectUs';
import Testimonials from './component/Testimonials';
import Contact from './component/Contact';
import Counter from './component/Counter';
import BenefitsOurServices from './component/BenefitsOurServices';


function App() {
  return (
    <div className="App">
      <AppNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/User" element={<User />} />
        <Route path="/Getinfo" element={<GetInfo />} />
        <Route path="/HowWorks" element={<HowWorks/>} />
        <Route path="/OurClient" element={<OurClient/>} />
        <Route path="/FollowPage" element={<FollowPage/>} />
        <Route path="/OurServices" element={<OurServices/>} />
        <Route path="/ConnectUs" element={<ConnectUs/>} />
        <Route path="/Contact" element={<Contact/>} />
        <Route path="/Testimonials" element={<Testimonials/>} />
        <Route path="/Counter" element={<Counter/>} />
        <Route path="/BenefitsOurServices" element={<BenefitsOurServices/>} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}
export default App;
