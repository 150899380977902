import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <section className='testimonials'>
      <div className='main-heading'>
        <h2><span>Testimonials</span></h2>
      </div>
      <Container className='p-0'>
        <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
            <Row>
              <Col sm={6}>
                <Card>
                  <Card.Body>
                    <Card.Text>Experience the Power of Seamless Transactions. Our innovative platform empowers businesses with flexible payment solutions. Easily manage payments, ensure secure transactions, and effortlessly </Card.Text>
                    <div className='testimonials-card'>
                      <div>
                        <Card.Img
                          className="d-block"
                          src="assets/images/seo1.png"
                          alt="First card"
                        />
                      </div>
                      <div className='profile'>
                        <Card.Title>Ram Mohan</Card.Title>
                        <p>Web Designer</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6}>
              <Card>
                  <Card.Body>
                    <Card.Text>Experience the Power of Seamless Transactions. Our innovative platform empowers businesses with flexible payment solutions. Easily manage payments, ensure secure transactions, and effortlessly </Card.Text>
                    <div className='testimonials-card'>
                      <div>
                        <Card.Img
                          className="d-block"
                          src="assets/images/seo1.png"
                          alt="First card"
                        />
                      </div>
                      <div className='profile'>
                        <Card.Title>Ram Mohan</Card.Title>
                        <p>Web Designer</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col sm={6}>
                <Card>
                  <Card.Body>
                    <Card.Text>Experience the Power of Seamless Transactions. Our innovative platform empowers businesses with flexible payment solutions. Easily manage payments, ensure secure transactions, and effortlessly </Card.Text>
                    <div className='testimonials-card'>
                      <div>
                        <Card.Img
                          className="d-block"
                          src="assets/images/seo1.png"
                          alt="First card"
                        />
                      </div>
                      <div className='profile'>
                        <Card.Title>Ram Mohan</Card.Title>
                        <p>Web Designer</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6}>
              <Card>
                  <Card.Body>
                    <Card.Text>Experience the Power of Seamless Transactions. Our innovative platform empowers businesses with flexible payment solutions. Easily manage payments, ensure secure transactions, and effortlessly </Card.Text>
                    <div className='testimonials-card'>
                      <div>
                        <Card.Img
                          className="d-block"
                          src="assets/images/seo1.png"
                          alt="First card"
                        />
                      </div>
                      <div className='profile'>
                        <Card.Title>Ram Mohan</Card.Title>
                        <p>Web Designer</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col sm={6}>
                <Card>
                  <Card.Body>
                    <Card.Text>Experience the Power of Seamless Transactions. Our innovative platform empowers businesses with flexible payment solutions. Easily manage payments, ensure secure transactions, and effortlessly </Card.Text>
                    <div className='testimonials-card'>
                      <div>
                        <Card.Img
                          className="d-block"
                          src="assets/images/seo1.png"
                          alt="First card"
                        />
                      </div>
                      <div className='profile'>
                        <Card.Title>Ram Mohan</Card.Title>
                        <p>Web Designer</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6}>
              <Card>
                  <Card.Body>
                    <Card.Text>Experience the Power of Seamless Transactions. Our innovative platform empowers businesses with flexible payment solutions. Easily manage payments, ensure secure transactions, and effortlessly </Card.Text>
                    <div className='testimonials-card'>
                      <div>
                        <Card.Img
                          className="d-block"
                          src="assets/images/seo1.png"
                          alt="First card"
                        />
                      </div>
                      <div className='profile'>
                        <Card.Title>Ram Mohan</Card.Title>
                        <p>Web Designer</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col sm={6}>
                <Card>
                  <Card.Body>
                    <Card.Text>Experience the Power of Seamless Transactions. Our innovative platform empowers businesses with flexible payment solutions. Easily manage payments, ensure secure transactions, and effortlessly </Card.Text>
                    <div className='testimonials-card'>
                      <div>
                        <Card.Img
                          className="d-block"
                          src="assets/images/seo1.png"
                          alt="First card"
                        />
                      </div>
                      <div className='profile'>
                        <Card.Title>Ram Mohan</Card.Title>
                        <p>Web Designer</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6}>
              <Card>
                  <Card.Body>
                    <Card.Text>Experience the Power of Seamless Transactions. Our innovative platform empowers businesses with flexible payment solutions. Easily manage payments, ensure secure transactions, and effortlessly </Card.Text>
                    <div className='testimonials-card'>
                      <div>
                        <Card.Img
                          className="d-block"
                          src="assets/images/seo1.png"
                          alt="First card"
                        />
                      </div>
                      <div className='profile'>
                        <Card.Title>Ram Mohan</Card.Title>
                        <p>Web Designer</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col sm={6}>
                <Card>
                  <Card.Body>
                    <Card.Text>Experience the Power of Seamless Transactions. Our innovative platform empowers businesses with flexible payment solutions. Easily manage payments, ensure secure transactions, and effortlessly </Card.Text>
                    <div className='testimonials-card'>
                      <div>
                        <Card.Img
                          className="d-block"
                          src="assets/images/seo1.png"
                          alt="First card"
                        />
                      </div>
                      <div className='profile'>
                        <Card.Title>Ram Mohan</Card.Title>
                        <p>Web Designer</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6}>
              <Card>
                  <Card.Body>
                    <Card.Text>Experience the Power of Seamless Transactions. Our innovative platform empowers businesses with flexible payment solutions. Easily manage payments, ensure secure transactions, and effortlessly </Card.Text>
                    <div className='testimonials-card'>
                      <div>
                        <Card.Img
                          className="d-block"
                          src="assets/images/seo1.png"
                          alt="First card"
                        />
                      </div>
                      <div className='profile'>
                        <Card.Title>Ram Mohan</Card.Title>
                        <p>Web Designer</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </Container>
    </section>
  );
}

export default ControlledCarousel;
