import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function BenefitsOurServices() {
    // Sample data for the two <li> elements
    const benefitsData = [
        {
            id: 1,
            imageUrl: 'assets/images/digital-services.png',
            bgColor: '#e6e6e685', // Example background color
        },
        {
            id: 2,
            heading: 'Learn The Latest Skills',
            subHeading: 'The business must follow all government business laws to operate smoothly. Every GST-registered',
        },
    ];

    // Sample data for the second set of <li> elements
    const benefitsData2 = [
        {
            id: 3,
            imageUrl: 'assets/images/digital-services.png',
            bgColor: '#e6e6e685', // Example background color
        },
        {
            id: 4,
            heading: 'Another Benefit',
            subHeading: 'The business must follow all government business laws to operate smoothly. Every GST-registered.',
        },
    ];
    const benefitsData3 = [
        {
            id: 5,
            imageUrl: 'assets/images/digital-services.png',
            bgColor: '#e6e6e685', // Example background color
        },
        {
            id: 6,
            heading: 'Another Benefit',
            subHeading: 'The business must follow all government business laws to operate smoothly. Every GST-registered.',
        },
    ];

    return (
        <>
            <section className='py-5 benefits-services'>
                <div className='main-heading'>
                    <h2>Benefits From Our Online<span> Digital Services</span></h2>
                </div>
                <Container>
                    <Row>
                        <Col lg="6">
                            <img src="assets/images/login-bg.jpg" alt="man.png" width={600} className='img-fluid' />
                        </Col>
                        <Col lg="6">
                            <ul>
                                {benefitsData.map((item) => (
                                    <li key={item.id}>
                                        {item.imageUrl && (
                                            <div className='img-bg' style={{ backgroundColor: item.bgColor }}>
                                                <img
                                                    src={item.imageUrl}
                                                    alt={`benefit-${item.id}`}
                                                    className='img-fluid'
                                                    height={30}
                                                />
                                            </div>
                                        )}
                                        {item.heading && <h3>{item.heading}</h3>}
                                        {item.subHeading && <p>{item.subHeading}</p>}
                                    </li>
                                ))}
                            </ul>
                            <ul>
                                {benefitsData2.map((item) => (
                                    <li key={item.id}>
                                        {item.imageUrl && (
                                            <div className='img-bg' style={{ backgroundColor: item.bgColor }}>
                                                <img
                                                    src={item.imageUrl}
                                                    alt={`benefit-${item.id}`}
                                                    className='img-fluid'
                                                    height={30}
                                                />
                                            </div>
                                        )}
                                        {item.heading && <h3>{item.heading}</h3>}
                                        {item.subHeading && <p>{item.subHeading}</p>}
                                    </li>
                                ))}
                            </ul>
                            <ul>
                                {benefitsData2.map((item) => (
                                    <li key={item.id}>
                                        {item.imageUrl && (
                                            <div className='img-bg' style={{ backgroundColor: item.bgColor }}>
                                                <img
                                                    src={item.imageUrl}
                                                    alt={`benefit-${item.id}`}
                                                    className='img-fluid'
                                                    height={30}
                                                />
                                            </div>
                                        )}
                                        {item.heading && <h3>{item.heading}</h3>}
                                        {item.subHeading && <p>{item.subHeading}</p>}
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default BenefitsOurServices;
