import React from "react";
import HomeHeader from "../component/HomeHeader";
import User from "../component/OurFeatures";
import About from "../component/About";
import GetInfo from "../component/GetInfo";
import HowWorks from "../component/HowWorks";
import FollowPage from "../component/FollowPage";
import ConnectUs from "../component/ConnectUs";
import OurServices from "../component/OurServices";
import Testimonials from "../component/Testimonials";
import OurClient from "../component/OurClient";


function Home() {
  return (
    <React.Fragment>
      <HomeHeader/>
      <OurClient/>
      <OurServices/>
      <User/>
      <About/>
      <GetInfo/>
      <HowWorks/>
      <Testimonials/>
      <FollowPage/>
      <ConnectUs/>
    </React.Fragment>
  );
}
export default Home;
