import React from 'react';
import { Col, Container, Row, Image } from "react-bootstrap";
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <>
            <div className='main-footer'>
                {/* <section className='social-links shadow-lg'>
                    <Container>
                        <Row className='align-items-center'>
                            <Col lg={8}>
                                <h3>Don’t Forgot To Follow On Social Media</h3>
                                <p>This is Our Social Media Accounts</p>
                            </Col>
                            <Col lg={4} className='d-flex justify-content-end'>
                                <ul>
                                    <li>
                                        <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i class="fa-brands fa-instagram"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section> */}
                <footer >
                    <div className="upper-footer bg-dark text-white">
                        <Container>
                            <Row>
                                <Col xxl={3} xl={3} lg={3} md={6} className="my-3">
                                    <Image src="assets/images/white.png" alt="logo.png" />
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat hic illum vero aspernatur quam architecto voluptatum. Maxime dignissimos officiis vero!</p>
                                </Col>
                                <Col xxl={4} xl={3} lg={3} md={6} className="my-3">
                                    <h5>Others</h5>
                                    <ul>
                                        <li><i class="fa-solid fa-location-dot me-2"></i>Terms Policy</li>
                                        <li><i class="fa-solid fa-phone me-2"></i>Privacy Policy</li>
                                    </ul>
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={6} className="my-3">
                                    <h5>Contact</h5>
                                    <ul>
                                        <li><i class="fa-solid fa-location-dot me-2"></i>Sector-62 Noida </li>
                                        <li><i class="fa-solid fa-phone me-2"></i>91 84674 44657</li>
                                        <li><i class="fa-solid fa-envelope me-2"></i>Example@gmail.com</li>
                                    </ul>
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={6} className="my-3">
                                    <h5>We’re Here To Help!</h5>
                                    <p>Tell us what you’re looking for and we’ll get you connected to the right people.</p>
                                    <Link className='btn btn-white rounded-pill'>Contact</Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="footer-bottom bg-dark py-2  border-top">
                        <Container>
                            <Row>
                                <Col>
                                    <p className='text-white text-center mb-0'>All Rights Reserved © 2023 | SM DIGITAL BLOOM PRIVATE LIMITED</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </footer>
            </div>
        </>
    )
}
export default Footer
