import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const CounterComponent = () => {
  const [students, setStudents] = useState(0);
  const [users, setUsers] = useState(0);
  const [instructors, setInstructors] = useState(0);
  const [videoStreaming, setVideoStreaming] = useState(0);

  const maxStudents = 20000;
  const maxUsers = 200000;
  const maxInstructors = 50000;
  const maxVideoStreaming = 30000;
  const duration = 1000; // 10 seconds

  useEffect(() => {
    const startTime = new Date().getTime();

    const interval = setInterval(() => {
      const elapsedTime = new Date().getTime() - startTime;
      const progress = Math.min(1, elapsedTime / duration);

      setStudents(Math.min(maxStudents, Math.round(progress * maxStudents)));
      setUsers(Math.min(maxUsers, Math.round(progress * maxUsers)));
      setInstructors(Math.min(maxInstructors, Math.round(progress * maxInstructors)));
      setVideoStreaming(Math.min(maxVideoStreaming, Math.round(progress * maxVideoStreaming)));

      if (progress >= 1) {
        clearInterval(interval);
      }
    }, 100); // Adjust the interval duration for smoother increments

    // Clear the interval when the component unmounts or when the max values are reached
    return () => clearInterval(interval);
  }, [maxStudents, maxUsers, maxInstructors, maxVideoStreaming, duration]);

  return (
    <section className='counter-section py-3'>
      <Container>
        <Row>
          <h3>Counter <span className='span'>Component</span></h3>
          <Col>
            <div className='d-flex my-3'>
              <p>{students.toLocaleString()} <br/><span>Students</span></p>
              <p>{users.toLocaleString()} <br/><span>Users</span></p>
              <p>{instructors.toLocaleString()} <br/><span>Instructors</span></p>
              <p>{videoStreaming.toLocaleString()} <br/><span>Video Streaming</span></p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CounterComponent;
