import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

function About() {
  return (
   <>
    <section className='about-section pt-5 bg-light'>
      <Container>
        <Row>
          <Col lg={6} className='px-5'>
               <h4>Welcome to our Website</h4>
               <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione quidem illum laudantium veritatis totam architecto iusto sed aperiam. Atque voluptas qui similique nobis eum odio eveniet fuga magnam aspernatur architecto.</p>
               <h className='mt-5'>WHO WE ARE </h>
               <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis obcaecati tempora exercitationem quas ullam expedita optio cupiditate aliquid, ex in magni atque dolor autem eum reiciendis animi esse, culpa velit!</p>
               <a href='' className='btn mt-3 learn-more-btn'>Learn More</a>
          </Col>
          <Col lg={6}>
           <div className='left-waptal'>
           <div>
              <img src="assets/images/seo2.png" alt="seo1.png" width={350}  className='img-fluid img-thumbnail'/> 
            </div>
            <div className='about-img'>
              <img src="assets/images/seo1.png" alt="seo1.png" width={350}  className='img-fluid img-thumbnail'/> 
            </div>
           </div>
          </Col>
        </Row>
      </Container>
    </section>
   </>
   
  )
}
export default About
