import { Container, Col, Row } from 'react-bootstrap';

function HowWorks() {
  const data = [
    {
      id: 1,
      src: "assets/images/bullhorn.png",
      title: "Marketing",
      title2: "Lorem ipsum dolor sit",
      subtitle: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ex corporis iure nihil quos,"
    },
    {
      id: 3,
      src: "assets/images/bullhorn.png",
      title: "Marketing",
      title2: "Lorem ipsum dolor sit",
      subtitle: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ex corporis iure nihil quos,"
    },
    {
      id: 3,
      src: "assets/images/bullhorn.png",
      title: "Marketing ",
      title2: "Lorem ipsum dolor sit",
      subtitle: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ex corporis iure nihil quos,"
    }
  ];

  return (
    <>
      <section className='py-5 how-works bg-light'>
        <Container>
          <h3 className='text-center mb-5'>How <span className='span'> We Works</span> </h3>
          <Row>
            {data.map((ram) => (
              <Col key={ram.id} lg={4} className='border-right'>
                <div className='feature-text text-center'>
                  <img src={ram.src} alt={ram.title} />
                  <h5>{ram.title}</h5>
                  <p>{ram.title2}</p>
                  <p>{ram.subtitle}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default HowWorks;
