import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

function ConnectUs() {
    return (
        <>
            <section className='py-5 bg-white connect-us'>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={4}>
                            <img src="assets/images/woman-call.png" alt="seo1.png" width={300} className='img-fluid' />
                        </Col>
                        <Col lg={8}>
                            <h3>Connect<span className='span'> With Us</span> </h3>
                            <p>Give us a call! Our team is available Monday to Friday, from 9:00 AM to 7:00 PM.</p>
                            <div>
                                <a href="tel:9318331978"><i className='fa fa-phone'></i> +91 9318331978</a><br></br>
                                <a href="mailto:parveensavit000@gmail.com"><i className="fa-solid fa-envelope me-2"></i>parveensavit000@gmail.com</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default ConnectUs
