import React from 'react';
import { Link } from 'react-router-dom';


function PageNotFound() {
    return (
        <div>
            <h1 className='text-center py-5'>Page Not Found</h1>
            <Link to="/" className="btn btn-primary">
                Back to Home
            </Link>
        </div>
    )
}

export default PageNotFound
